import React from "react";
import { Helmet } from "react-helmet";
import Layout from "components/Layout";

const Merci = ({ pageContext: content, location }) => {
  const {
    _companies,
    _countries,
    _others,
    _otherCountries,
    _staticTexts,
    _topMenus,
    _companyMenus,
    _lang,
 _blogs,
  } = content;

  return (
    <Layout
      menuContent={{
        companies: _companies,
        countries: _countries,
        others: _others,
        otherCountries: _otherCountries,
        topMenus: _topMenus,
        companyMenus: _companyMenus,
        blogs: _blogs,
      }}
      ratingValue={content.ratingValue || 4.9}
      ratingCount={content.ratingCount || 121}
      staticTexts={_staticTexts}
      lang={_lang}
    >
      <Helmet>
        <title>Losali Direct</title>
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="container page-width top-margin">
        <div className="aide card-primary">
          <form>
            <h1 className="main-card-h1">
              {_staticTexts.templateMerciThankyou}
            </h1>
          </form>
        </div>
      </main>
    </Layout>
  );
};

export default Merci;
